#signUp,
#signUpEmployer {
    background-color: #fff;
    padding: 20px;
    margin: 5% auto 10px;
}

.heading {
    color: #1c8adb;
}

.fa-facebook {
    margin-right: 1rem;
}

.facebookBtn {
    background-color: #4267B2 !important;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    padding: 0.5rem;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    width: 100%;
  }

.btn-link {
    outline:none !important;
    text-decoration: none !important;
}

.btn-link:focus ,
.btn-link:hover
 {
    box-shadow: none !important;
    outline:none !important;
    text-decoration: none !important;
    
}

@media only screen and (max-width: 767px) {
    .divider {
        border-left: none;
        border-top: 1px solid #c7c8c9;
    }
    .heading {
        margin-top: 10px !important;
    }
}