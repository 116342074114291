.footerDiv {
    padding: 30px 0px !important;
}

.footerImg .row img {
    transition: 0.5s ease-in-out;
}

.footerImg .row:hover img {
    transform: scale(0.75);
}

.zomInDetails {
    text-align: right !important;
}

.zomINSocialLink {
    padding: 30px 0 !important;
}

.zomINSocialLink svg {
    font-size: 30px !important;
    color: #5cc9e1 !important;
    color: #5cc9e1 !important;
    transition: 0.5s;
}
.zomINSocialLink svg:hover {
    color: #262626 !important;
}

@media (max-width: 767.98px) { 
    .footerDiv,
    .zomInDetails {
        text-align: center !important;
    }

    .footerDiv .row {
        margin: 10px !important;
    }
    .footerDiv .row .col,
    .zomInDetails {
        padding-top: 20px !important;
    }
 }