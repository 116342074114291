.required:after {
  content: "*";
  color: red;
}
#logo {
  width: 150px;
}

.nav-tabs {
  overflow-x: auto !important;
  overflow-y:hidden !important;
  flex-wrap: nowrap !important;
}
.nav-tabs .nav-link {
 white-space: nowrap !important;
}

.paginateBtn {
  height: 40px;
  margin: 3rem auto;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginateBtn a {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 5px;
  border: 1px solid #262626;
  color: #262626;
  cursor: pointer;
  text-decoration:none !important;
}

.paginateBtn a:hover {
  color: #fff;
  background-color: #5bc9e1;
  text-decoration:none !important;
}

.paginateActive a {
  color: #fff;
  background-color: #5bc9e1;
  text-decoration:none !important;
}


.divider {
  border-left: 1px solid #c7c8c9;
}

.text-justify {
  text-align: justify !important;
}

.icon {
  height: 1rem;
  width: auto;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.text-truncate-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.themeColorUser {
  background-color: #e3f2fd !important;
}

.themeColorUser:hover {
  background-color: #cbe7fb !important;
}

:target{
  padding-top: 5rem !important;
}

.fs-7 {
  font-size: 0.8rem !important;
}

#home .ck.ck-reset_all,
#home .ck.ck-reset_all * {
  display: none !important;
}

#home .ck-placeholder {
  min-height: 100px !important;
}

#home .ck-content,
#home .ck-content:focus {
  border: none !important;
}
#home .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  box-shadow: none !important;
}

@media (max-width: 576px) {
  .icon-xs {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}