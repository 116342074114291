.searchbox {
    background-color: #e8e8e8 !important;
}

.cardPartner {
    min-height: 350px;
    padding: 2rem 0.5rem;
    background-color: #fff;
    align-items: flex-end;
    transition: 0.5s ease-in-out;
    border: none !important;
    text-align: center !important;
    border-top-left-radius: 300px !important;
    border-top-right-radius: 300px !important;
    overflow: hidden;
    margin: 1rem 0 !important;
    max-width: calc(25% - 1rem) !important;
    max-height: 100% !important;
}

.cardPartner:hover {
    transform: translateY(10px);
    background-color: #f7f7f7 !important;
}

.cardImg {
    transition: 0.5s !important;
    margin: auto !important;
}

.cardImg img {
    min-width: 100%;
    height: 150px;
    width: 100%;
    border-radius: 50% !important;
    padding: 5px 10px !important;
    object-fit: contain;
    transition: 0.5s !important;
}

.container .cardPartner:hover .cardImg img {
    transform: scale(0.75);
}

.partnerContent {
    text-align: justify !important;
}

.module {
    margin: 0 0 1em 0;
    overflow: hidden;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.sidebarHeader {
    text-align: right !important;
}


/* PartnerLanding page  */

.partnerCoverImg {
    min-width: 100% !important;
    width: 970px !important;
    max-height: 250px !important;
    object-fit: cover;
}

.partnerCoverLogo img {
    transform: translate(25%, -50%)!important;
    border-radius: 50% !important;
    border: 5px solid #fff !important;
    min-width: 20px !important;
    object-fit: cover;
    width: 150px !important;
}

.overview {
    text-align: center !important;
    align-items: center!important;
}

.video-wrapper {
    height: 100%;
    width: 100%;
    min-height: 360px;
    margin: 10px auto !important;
}

.about {
    text-align: justify !important;
    padding: 10px 30px !important;
}

.about>img {
    width: 100% !important;
    min-height: 250px !important;
    object-fit: contain !important;
    margin: auto 10px !important;
}


/* Medium Device */

@media (max-width: 1199.98px) {
    .partnerCoverLogo img {
        transform: translate(10%, -50%)!important;
    }
}


/* Small Device */
@media (max-width: 767.98px) {
    .cardPartner {
        max-width: calc(50% - 1rem) !important;
    }
    .partnerTopContent {
        margin-top: 50px !important;
        text-align: center !important;
    }
    .partnerCoverLogo img {
        transform: translate(-50%, -50%)!important;
        margin-right: auto!important;
        margin-left: auto!important;
        top: 50% !important;
        left: 50% !important;
        width: 100px !important;
    }
}